<template>
  <div class="container">
    <div class="header">
      <nav class="nav-bar">
        <ul class="nav-links">
          <li><a href="#about">About</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#gallery">Gallery</a></li>
          <li><a href="#social-media">Social Media</a></li>
        </ul>
      </nav>
    </div>
    <div class="content">
      <h1>Artist Name</h1>
      <div class="about" id="about">
        <h2>About</h2>
        <div class="div">
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae tortor lectus. Mauris at sem ut magna auctor laoreet in ut lorem. Fusce ultrices arcu vel blandit rhoncus. Donec viverra blandit nunc, vel pulvinar nisi feugiat id. Duis vestibulum, tortor a iaculis pretium, ipsum nunc fringilla urna, eu laoreet nisi urna ut turpis. </p>
          <p class="text">In hac habitasse platea dictumst. Vivamus at enim finibus, iaculis nisi vel, rhoncus lorem. Suspendisse vitae molestie dolor. Aenean sed nibh eget metus pretium ullamcorper non eu magna. Praesent ultricies metus quis tortor lobortis faucibus.</p>
        </div>
      </div>
      <div class="projects" id="projects">
        <h2>Projects</h2>
        <div class="list-container">
          <ul class="fancy-list">
            <li class="fancy-list-item">Project 1</li>
            <li class="fancy-list-item">Project 2</li>
            <li class="fancy-list-item">Project 3</li>
          </ul>
        </div>
      </div>
      <div class="gallery" id="gallery">
        <h2>Gallery</h2>
        <div class="slider">
          <transition name="fade">
            <img :src="images[currentImage]" :key="currentImage">
          </transition>

          <div class="slider-navigation">
            <button @click="prevImage" :disabled="currentImage === 0">
              <i class="fas fa-angle-left"></i>
            </button>
            <button @click="nextImage" :disabled="currentImage === images.length - 1">
              <i class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="social-media" id="social-media">
        <h2>Social Media</h2>
        <div class="list-container">
          <ul class="fancy-list">
            <li class="fancy-list-item">Facebook</li>
            <li class="fancy-list-item">Instagram</li>
            <li class="fancy-list-item">TikTok</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        require('@/assets/slider_pictures/piano1.jpg'),
        require('@/assets/slider_pictures/piano2.jpg'),
        require('@/assets/slider_pictures/piano3.png')
        // add more images as necessary
      ],
      currentImage: 0,
    }
  },
  methods: {
    prevImage() {
      if (this.currentImage > 0) {
        this.currentImage--;
      }
    },
    nextImage() {
      if (this.currentImage < this.images.length - 1) {
        this.currentImage++;
      }
    },
  },
};
</script>

<style>
body {
  background-color: #152238;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.header {
  text-align: center;
  background-color: darkpurple;
  color: white;
  padding: 20px;
}

.header h1 {
  margin: 0;
}

.navbar {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-size: 18px;
  border-radius: 10px;
  padding: 10px;
  font-family: "Tahoma", CenturyGothic, Geneva, AppleGothic, sans-serif;
}

.nav-links li a:hover {
  color: #818181;
}

.list-container {
  display: flex;
  justify-content: center;
}

.fancy-list {
  list-style: none;
  padding: 0;
}

.fancy-list-item {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #f1f1f1;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
}

.fancy-list-item:hover {
  transform: scale(1.1);
  background-color: #333;
  color: #fff;
}

.text {
  font-size: 16px;
  line-height: 1.5;
  color: #9ab8ec;
  font-family: "Tahoma", CenturyGothic, Geneva, AppleGothic, sans-serif;
}

.div {
  background-color: #273a5a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.div h2 {
  margin-top: 0;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1c2e4a;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.5s;
}

.button:hover {
  background-color: black;
}

.gallery h2 {
  margin-top: 0;
}

h1, h2 {
  font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;
  color: white;
  margin-bottom: 20px;
}

.slider {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-navigation {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.slider-navigation button {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.slider-navigation button i {
  transform: scale(1.5);
}

.slider-navigation button:hover {
  transform: scale(1.2);
}

.slider-navigation button:disabled {
  opacity: 0.5;
  cursor: default;
  transform: scale(1);
}

.slider-navigation button:disabled i {
  color: gray;
}

a {
  color: #666;
  font-weight: normal;
  text-decoration: none;
}

a:hover {
  color: #333;
}

</style>
